<template>
  <div>
    <Loader />
    <Customizer @onLogo="changeLogo" @toggle="sidebarMini" @animationChange="routerAnimationChange" />
    <div class="wrapper">
      <!-- Sidebar  -->
      <Sidebar :items="verticalMenu" :logo="logo" :onlyLogo="onlyLogo" :onlyLogoText="onlyLogoText"
        @toggle="sidebarMini" :toggleButton="toggleSideIcon" :sidebarGroupTitle="sidebarGroupTitle" />
      <!-- TOP Nav Bar -->
      <DefaultNavBar title="" :homeURL="{ name: 'dashboard.webmonitoring' }" :sidebarGroupTitle="sidebarGroupTitle"
        @toggle="sidebarMini" :logo="logo">
        <template slot="responsiveRight">
          <ul class="navbar-nav ml-auto navbar-list">
            <li class="nav-item nav-icon">
              <a href="#" class="search-toggle iq-waves-effect" >
                <lottie :option="require('../assets/images/small/lottie-bell')" id="lottie-beil" />
                <span class="bg-danger dots" @click="showDropdowns"></span>
              </a>
              <div class="iq-sub-dropdown" v-show="showDropdown">
                <div class="iq-card shadow-none m-0">
                  <div class="iq-card-body p-0">
                    <div class="bg-primary p-3 d-flex justify-content-between align-items-center">
                      <h5 class="mb-0 text-white">
                        {{ this.count }}
                        {{ $t('nav.allNotifications') }}
                      </h5>
                      <router-link to="/app/notifications" @click.native="hideDropdown">
                        <small class="badge badge-light pt-1">Show All</small>
                      </router-link>
                    </div>
                    <div v-if="this.count > 0" class="scrollable-area">
                      <a href="#" class="iq-sub-card" v-for="(item, index) in notification" :key="index">
                        <div class="media align-items-center">
                          <div class="media-body ml-3">
                            <h6 class="mb-0">{{ item.message }}</h6>
                            <small class="float-right font-size-12">{{ getTimeAgo(item.created_date) }}</small>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div v-else class="no-notifications">
                      No notifications available
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </template>
        <template slot="right">
          <ul class="navbar-list">
            <li class="line-height">
              <a href="#" class="search-toggle iq-waves-effect d-flex align-items-center">
                <img :src="userProfile" class="img-fluid rounded-circle" alt="user" />
              </a>
              <div class="iq-sub-dropdown iq-user-dropdown">
                <div class="iq-card shadow-none m-0">
                  <div class="iq-card-body p-0">
                    <!-- <div class="bg-primary p-3">
                                <h5 class="mb-0 text-white line-height">Hello Barry </h5>
                                <span class="text-white font-size-12">{{ $t('nav.user.available') }}</span>
                                </div> -->
                    <!-- <a href="#" class="iq-sub-card iq-bg-primary-hover">
                                <div class="media align-items-center">
                                  <div class="rounded iq-card-icon iq-bg-primary">
                                    <i class="ri-file-user-line"></i>
                                  </div>
                                  <div class="media-body ml-3">
                                    <h6 class="mb-0">{{ $t('nav.user.profileTitle') }}</h6>
                                    <p class="mb-0 font-size-12">{{ $t('nav.user.profileSub') }}</p>
                                  </div>
                                </div>
                                </a>  -->
                    <!-- <a href="/user/profile-edit/" class="iq-sub-card iq-bg-primary-hover">
                                <div class="media align-items-center">
                                  <div class="rounded iq-card-icon iq-bg-primary">
                                    <i class="ri-profile-line"></i>
                                  </div>
                                  <div class="media-body ml-3">
                                    <h6 class="mb-0">{{ $t('nav.user.profileEditTitle') }}</h6>
                                    <p class="mb-0 font-size-12">{{ $t('nav.user.profileEditSub') }}</p>
                                  </div>
                                </div>
                                </a> -->
                    <a href="/user/profile-edit/" class="iq-sub-card iq-bg-primary-hover">
                      <div class="media align-items-center">
                        <div class="rounded iq-card-icon iq-bg-primary">
                          <i class="ri-account-box-line"></i>
                        </div>
                        <div class="media-body ml-3">
                          <h6 class="mb-0">{{ $t('nav.user.accountSettingTitle') }}</h6>
                          <p class="mb-0 font-size-12">{{ $t('nav.user.accountSettingSub') }}</p>
                        </div>
                      </div>
                    </a>
                    <div class="d-inline-block w-100 text-center p-3">
                      <a class="bg-primary iq-sign-btn" href="" @click.prevent="logout" role="button">
                        {{ $t('nav.user.signout') }}
                        <i class="ri-login-box-line ml-2"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </template>
      </DefaultNavBar>
      <!-- TOP Nav Bar END -->
      <div id="content-page" class="content-page">
        <b-container fluid v-if="!notBookmarkRouts.includes($route.name)">
          <b-row>
            <!-- <BreadCrumb /> -->
          </b-row>
        </b-container>
        <transition name="router-anim" :enter-active-class="`animated ${animated.enter}`" mode="out-in"
          :leave-active-class="`animated ${animated.exit}`">
          <router-view />
        </transition>
      </div>
    </div>
    <LayoutFooter />
  </div>
</template>
<script>
import { core } from '../config/pluginInit'
import axios from "axios";
import constant from "@/config/constant";
import { mapActions, mapGetters } from 'vuex'
import Loader from '../components/core/loader/Loader'
import Sidebar from '../components/core/sidebars/Sidebar'
import DefaultNavBar from '../components/core/navbars/DefaultNavBar'
import SideBarItems from '../StaticData/json/SideBar'
import profile from '../assets/images/user/01.jpg'
// import loader from '../assets/images/loader.gif'
import Lottie from '../components/core/lottie/Lottie'
import Customizer from './Components/Customizer'
import WhiteLogo from '../assets/images/logo.png'
import LayoutFooter from './Components/LayoutFooter'
import CryptoJS from "crypto-js";
export default {
  name: 'VerticleLayout',
  components: {
    LayoutFooter,
    Customizer,
    Lottie,
    Loader,
    Sidebar,
    DefaultNavBar
  },
  mounted() {
    this.layoutSetting(this.$route.name)
    this.fetchNotificationData();
  },
  computed: {
    ...mapGetters({
      selectedLang: 'Setting/langState',
      langsOptions: 'Setting/langOptionState',
      darkMode: 'Setting/darkModeState',
      colors: 'Setting/colorState'
    }),
    toggleSideIcon() {
      let show = true
      switch (this.$route.name) {
        // case 'dashboard.home-1':
        // case 'dashboard.home-3':
        // case 'dashboard.home-4':
        // case 'dashboard.home-5':
        case 'dashboard.home-6':
          show = false
          break
      }
      return show
    }
  },
  watch: {
    $route: function (to, from) {
      this.layoutSetting(to.name)
    }
  },
  // sidebarTicket
  data() {
    return {
      showDropdown: true,
      notification: [],
      count: "",
      animated: { enter: 'fadeIn', exit: 'fadeOut' },
      verticalMenu: SideBarItems,
      userProfile: profile,
      onlyLogo: false,
      onlyLogoText: false,
      sidebarGroupTitle: true,
      logo: WhiteLogo,
      rtl: false,
      SmallSidebarLogo: WhiteLogo,
      message: [
        {
          image: require('../assets/images/user/01.jpg'),
          name: 'Nik Emma Watson',
          date: '13 jan'
        },

      ],

      notBookmarkRouts: [
        'dashboard.home-1',
        'dashboard.home-2',
        'dashboard.home-3',
        'dashboard.home-4',
        'dashboard.home-5',
        'dashboard.home-6'
      ]
    }
  },
  methods: {
    hideDropdown() {
      this.showDropdown = false;
    },
    showDropdowns() {
      this.showDropdown = true;
    },
    getTimeAgo(date) {
      const currentDate = new Date();
      const createdAt = new Date(date);
      const timeDifference = currentDate - createdAt;

      // Convert milliseconds to minutes
      const minutes = Math.floor(timeDifference / 60000);

      if (minutes < 60) {
        return `${minutes} minutes ago`;
      } else if (minutes < 1440) { // 1440 minutes in a day
        const hours = Math.floor(minutes / 60);
        return `${hours} hours ago`;
      } else {
        const days = Math.floor(minutes / 1440);
        return `${days} days ago`;
      }
    },
    decryptToken(encryptedToken, key) {
      const bytes = CryptoJS.AES.decrypt(encryptedToken, key);
      const decryptedToken = bytes.toString(CryptoJS.enc.Utf8);
      return decryptedToken;
    },
    async fetchNotificationData() {
      try {

        const token = localStorage.getItem("token");
        const key = "enc";
        const decryptedToken = this.decryptToken(token, key);

        // Make your API call here
        const response = await axios.get(
          constant.API_BASE_URL + "network/notifications/?page=1&ordering=-created_date",
          {
            headers: {
              Authorization: "Token " + decryptedToken,
            },
          }
        );

        // Handle your API response here
        this.count = response.data.count
        this.notification = response.data.data;
        // console.log("API Response:", this.notification);

      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },

    layoutSetting(routeName) {
      this.onlyLogo = true
      this.onlyLogoText = true
      this.sidebarGroupTitle = true
      switch (routeName) {
        case 'dashboard.home-5':
          this.onlyLogoText = true
          this.onlyLogo = false
          break
        case 'dashboard.home-6':
          this.logo = WhiteLogo
          this.onlyLogo = true
          this.onlyLogoText = false
          this.sidebarGroupTitle = false
          break
        default:

          this.logo = WhiteLogo
          break
      }
    },
    changeLogo(e) {
      this.logo = e
    },
    sidebarMini() {
      core.triggerSet()
      this.$store.dispatch('Setting/miniSidebarAction')
    },
    decryptToken(encryptedToken, key) {
      const bytes = CryptoJS.AES.decrypt(encryptedToken, key);
      const decryptedToken = bytes.toString(CryptoJS.enc.Utf8);
      return decryptedToken;
    },

    getCookie(name) {
      let cookieValue = null;
      if (document.cookie && document.cookie !== "") {
        const cookies = document.cookie.split(";");
        for (let i = 0; i < cookies.length; i++) {
          const cookie = cookies[i].trim();
          if (cookie.startsWith(name + "=")) {
            cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
            break;
          }
        }
      }
      return cookieValue;
    },
    async logout() {
      try {
        const token = localStorage.getItem("token");
        const superadmin = localStorage.getItem("superadmin");
        localStorage.removeItem('token');
        localStorage.removeItem('superadmin');
        this.$router.push({ name: 'auth.sign-in' });
      } catch (error) {
        console.error("Error fetching website data:", error);
      }
    },

    langChange(lang) {
      this.langChangeState(lang)
      this.$i18n.locale = lang.value
      document.getElementsByClassName('iq-show')[0].classList.remove('iq-show')
      if (lang.value === 'ar') {
        this.rtlAdd(lang)
      } else {
        this.rtlRemove(lang)
      }
    },
    routerAnimationChange(e) {
      this.animated = e
    },
    ...mapActions({
      removeToCart: 'Ecommerce/removeToCartAction',
      langChangeState: 'Setting/setLangAction',
      rtlAdd: 'Setting/setRtlAction',
      rtlRemove: 'Setting/removeRtlAction'
    })
  }
}
</script>
<style>
@import url("../assets/css/custom.css");
@import url("../assets/css/PriceSlider.css");

.scrollable-area {
  max-height: 400px;
  /* Aap is value ko apni jarurat ke according adjust kar sakte hain */
  overflow-y: auto;
  max-width:800px;
}
</style>
